<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <form class="mt-4" v-on:submit.prevent="mergeSong">
            <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
              <div class="col-span-2">
                <label for="link" class="font-semibold">Link bài hát muốn giữ (*)</label>
                <input required v-model="link" placeholder="" class="shadow-none with-border" id="link" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <label for="link_delete" class="font-semibold">Link bài hát muốn xóa (*)</label>
                <input required v-model="link_delete" placeholder="" class="shadow-none with-border" id="link" type="text" autocomplete="off">
              </div>
              <div class="col-span-2">
                <label for="file_used" class="font-semibold">File nhạc muốn giữ (*)</label>
                <select class="form-control" v-model="file_used">
                  <option value="">Bài được giữ</option>
                  <option value="deleted">Bài bị xóa</option>
                </select>
              </div>
              <div class="col-span-2">
                <button type="submit"
                      class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">Gộp bài hát
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import {changePageTitle, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "MergeSong",
  data() {
    return {
      link: "",
      link_delete: "",
      file_used: ""
    }
  },
  methods: {
    mergeSong() {
      let query = `mutation($link: String!, $link_delete: String!, $file_used: String) {
        mergeSong(link: $link, link_delete: $link_delete, file_used: $file_used) {
          id
        }
      }`;

      ApiService.graphql(query, {link: this.link, link_delete: this.link_delete, file_used: this.file_used})
        .then(({data}) => {
          if (data.data && data.data.mergeSong) {
            this.link = "";
            this.link_delete = "";
            this.file_used = "";
            this.$toast.success("Gộp bài hát thành công");
          } else if(data.errors) {
            this.$toast.error("Gộp bài hát không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Gộp bài hát không thành công: " + response.message);
        });
    }
  },
  mounted() {
    if (!isAdmin) {
      this.$router.push({name: "Home"});
      return;
    }
    changePageTitle("Gộp bài hát");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  }
}
</script>
